import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import {  RECRUIT_URL } from '../common/config'
import { createUploadLink } from 'apollo-upload-client'

// const accessToken = localStorage.getItem('token');
const queryParameters = new URLSearchParams(window.location.search)
const parame_token = queryParameters.get("token")
const param_value = JSON.parse(queryParameters.get("value"))
 
if (parame_token!== null) {
  delete param_value.__typename
  console.log("token"+parame_token)
  console.log( param_value)
  localStorage.setItem("authUser", JSON.stringify(param_value));
  localStorage.setItem("authToken", JSON.stringify(parame_token));
  
}
const httpLinkUrl = createUploadLink({
  uri: RECRUIT_URL
  // headers: {
  //     authorization: accessToken ? `Bearer ${accessToken}` : '',
  // },
});

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem('authToken');
  if(authToken){
    return {
      headers: {
        authorization: authToken ? `Bearer ${authToken.replace(/^"(.+)"$/,'$1')}` : ''
      }
    };

  } else {
    return {
      headers: headers
    };

  }
  
});

const httpLink = authLink.concat(httpLinkUrl);

export default httpLink;
